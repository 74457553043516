<template>
  <div>
    <!-- Line 群組歡迎 -->
    <div class="border p-3 rounded rounded-5 mb-3">
      <p class="fw-bolder mb-3">Line 群組歡迎設定</p>
      <ul v-if="!storeLineGroupLinks.loading">
        <template v-if="storeLineGroupLinks.data.length > 0">
          <li v-for="data in storeLineGroupLinks.data" :key="data.id">
            <div class="form-check form-switch p-0">
              <input
                class="form-check-input m-0 me-2"
                type="checkbox"
                role="switch"
                :id="`LineGroupWelcomeSwitch${data.id}`"
                v-model="data.greet"
                @change="setLineAttributes('setGreet', data)"
              />
              <label
                class="form-check-label"
                :for="`LineGroupWelcomeSwitch${data.id}`"
                >開啟
                <span class="text-primary fw-bolder">{{ data.groupName }}</span>
                歡迎功能</label
              >
            </div>
            <div class="form-floating mb-3" v-if="data.greet">
              <textarea
                class="form-control"
                placeholder="Leave a comment here"
                :id="`LineGroupWelcomeText${data.id}`"
                style="height: 200px"
                maxlength="4000"
                v-model="data.greetingMessage"
              ></textarea>
              <label :for="`LineGroupWelcomeText${data.id}`"
                >歡迎字樣(4000字)</label
              >
            </div>
            <div class="text-end" v-if="data.greet">
              <button
                class="tw-btn tw-btn-success"
                @click="setLineAttributes('setGreetingMessage', data)"
              >
                儲存歡迎字樣
              </button>
            </div>
          </li>
        </template>
        <p class="alert alert-danger fw-bolder p-2" v-else>
          您尚未在此賣場綁定任何 Line Bot 群組 !!
          <router-link :to="`/seller/store/${storeId}/social`"
            >點我前往綁定</router-link
          >
        </p>
      </ul>
      <AreaLoading v-else></AreaLoading>
    </div>
    <!-- line 推播列表文字 -->
    <div class="border p-3 rounded rounded-5 mb-3">
      <p class="fw-bolder mb-3">Line 推播列表文字</p>
      <div class="form-floating mb-3">
        <input
          type="text"
          class="form-control"
          id="LineD4Text"
          placeholder="預設文字10字"
          maxlength="10"
          v-model="linePushD4Text.value"
        />
        <label for="LineD4Text">預設文字(10字)</label>
      </div>
      <div class="text-end">
        <button
          class="tw-btn tw-btn-success"
          @click="
            updateConfiguration(
              'p_merchandise',
              'linePushD4Text',
              linePushD4Text
            )
          "
        >
          儲存
        </button>
      </div>
    </div>
    <!-- Line 推播樣式 -->
    <div class="border p-3 rounded rounded-5 mb-3">
      <p class="fw-bolder mb-3">Line 推播樣式</p>
      <ul v-if="!storeLineGroupLinks.loading">
        <template v-if="storeLineGroupLinks.data.length > 0">
          <li v-for="data in storeLineGroupLinks.data" :key="data.id">
            <p class="text-primary fw-bolder mb-2">{{ data.groupName }}</p>
            <div class="form-check form-switch p-0">
              <input
                class="form-check-input m-0 me-2"
                type="checkbox"
                role="switch"
                :id="`PushCarousel${data.id}`"
                v-model="data.pushCarousel"
                @change="setLineAttributes('setPushCarousel', data)"
              />
              <label class="form-check-label" :for="`PushCarousel${data.id}`">
                橫幅顯示
              </label>
            </div>
            <div class="form-check form-switch p-0">
              <input
                class="form-check-input m-0 me-2"
                type="checkbox"
                role="switch"
                :id="`PushQueueInfo${data.id}`"
                v-model="data.pushQueueInfo"
                @change="setLineAttributes('setPushQueueInfo', data)"
              />
              <label class="form-check-label" :for="`PushQueueInfo${data.id}`">
                包含記事本格式
              </label>
            </div>
          </li>
        </template>
        <p class="alert alert-danger fw-bolder p-2" v-else>
          您尚未在此賣場綁定任何 Line Bot 群組 !!
          <router-link :to="`/seller/store/${storeId}/social`"
            >點我前往綁定</router-link
          >
        </p>
      </ul>
      <AreaLoading v-else></AreaLoading>
    </div>
    <!-- 發布到 line notify 下單連結位置 -->
    <div class="border p-3 rounded rounded-5 mb-3">
      <p class="fw-bolder mb-3">發布到 line notify 下單連結位置</p>
      <div class="select-radio">
        <label v-for="data in placeOnOrderUrlPosition" :key="data.id">
          <input
            type="radio"
            name="rows"
            :checked="data.selected"
            @click="
              updateConfiguration('p_line', 'placeOnOrderUrlPosition', data)
            "
          />
          <span class="radio-style">{{
            data.value === "d4" ? "預設" : "最下面"
          }}</span>
        </label>
      </div>
    </div>
    <!-- Line 關鍵字 -->
    <div class="border p-3 rounded rounded-5 mb-3">
      <p class="fw-bolder mb-3">Line 關鍵字</p>
      <table class="table table-hover">
        <thead>
          <tr>
            <th>關鍵字</th>
            <th>描述</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>關鍵字清單</td>
            <td>
              可以在群組中得到所有關鍵字--<span class="text-success"
                >群組內皆可</span
              >
            </td>
          </tr>
          <tr>
            <td>推播商品</td>
            <td>
              可以將賣場內待推播列表中的商品推播到群組上--<span
                class="text-danger"
                >僅限賣家/小幫手</span
              >
            </td>
          </tr>
          <tr>
            <td>個人菜單</td>
            <td>
              方便讓您賣場中的顧客快速前往查看"訂單"與"帳單"--<span
                class="text-success"
                >群組內皆可</span
              >
            </td>
          </tr>
          <tr>
            <td>商品許願</td>
            <td>
              可以讓您的顧客前往許願頁面進行許願--<span class="text-success"
                >群組內皆可</span
              >
            </td>
          </tr>
          <tr>
            <td>抽獎</td>
            <td>
              顯示抽獎清單--<span class="text-danger">僅限賣家/小幫手</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      storeId: 0,
      serverToken: '',
      storeLineGroupLinks: {
        loading: false,
        data: [],
      },
    }
  },
  created() {
    this.initialization()
    this.getPrefer()
    this.getStoreInfo()
  },
  computed: {
    linePushD4Text() {
      return this.$store.state.Prefer.p_merchandise.linePushD4Text[0]
    },
    placeOnOrderUrlPosition() {
      return this.$store.state.Prefer.p_line.placeOnOrderUrlPosition
    },
  },
  methods: {
    initialization() {
      this.storeId = this.$route.params.storeId
      this.serverToken = this.$methods.getCookie('serverToken')
    },
    // 取得偏好
    getPrefer() {
      this.$store.dispatch('Prefer/getStoreInfo', {
        storeId: this.$route.params.storeId,
        serverToken: this.$methods.getCookie('serverToken')
      })
    },
    // 取得 line 資訊
    getStoreInfo() {
      this.$methods.switchLoading('show')
      const vm = this
      const getStoreInfoApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          type: 5,
          ids: [this.storeId],
          methods: '{getStoreLineGroupLinks{getSummary}}'
        }
      ]
      this.storeLineGroupLinks = {
        loading: true,
        data: []
      }
      $.ajax({
        type: 'POST',
        async: true,
        url: getStoreInfoApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const storeInfo = res.data[0].objects[0]
            if (storeInfo.storeLineGroupLinks.length === 0) vm.storeLineGroupLinks.loading = false
            else {
              storeInfo.storeLineGroupLinks.forEach(item => {
                if (!item.deleted) {
                  vm.storeLineGroupLinks.data.push({
                    deleted: item.deleted,
                    id: item.id,
                    greet: item.greet,
                    greetingMessage: item.greetingMessage,
                    groupId: item.groupId,
                    storeId: item.storeId,
                    groupName: item.summary.groupName,
                    pushCarousel: item.pushCarousel,
                    pushQueueInfo: item.pushQueueInfo,
                  })
                }
              })
              vm.storeLineGroupLinks.loading = false
            }
        }
          setTimeout(() => {
            vm.$methods.switchLoading('hide')
          }, 500)
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 更新偏好
    updateConfiguration(category, item, data) {
      if (item === 'linePushD4Text' && !data.value) return this.SweetAlert('other', '預設文字不能為空')

      this.$store.dispatch('Prefer/updateConfiguration', {
        storeId: this.$route.params.storeId,
        serverToken: this.$methods.getCookie('serverToken'),
        category: category,
        item: item,
        data: data
      })
    },
    // 更新 Line 偏好
    setLineAttributes(updateItem, item) {
      const vm = this
      const setGroupAttributeApi = `${process.env.VUE_APP_API}/apps/line/setGroupAttribute`
      const header = {
        authorization: this.serverToken
      }
      const data = []
      switch (updateItem) {
        case 'setGreet':
          data.push({
            id: item.id,
            attributes: {
              setGreet: item.greet
            },
          })
          break;
        case 'setGreetingMessage':
          data.push({
            id: item.id,
            attributes: {
              setGreetingMessage: item.greetingMessage
            },
          })
          break;
        case 'setPushCarousel':
          data.push({
            id: item.id,
            attributes: {
              setPushCarousel: item.pushCarousel
            },
          })
          break;
        case 'setPushQueueInfo':
          data.push({
            id: item.id,
            attributes: {
              setPushQueueInfo: item.pushQueueInfo
            },
          })
          break;
        default:
          throw new Error('PS_Line.vue > setLineAttributes > updateItem 未處理')
      }
      this.$methods.switchLoading('show')
      $.ajax({
        type: 'PUT',
        async: true,
        url: setGroupAttributeApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.getStoreInfo()
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
  },
}
</script>